import axios from 'axios';

const baseHost = process.env.VUE_APP_BASE_HOST;

const apiBase = axios.create({ baseURL: baseHost });

apiBase.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

apiBase.interceptors.response.use(
  (response) => ((response.status === 200 || response.status === 201)
    ? Promise.resolve(response.data)
    : Promise.reject(response.data)),
  (error) => Promise.reject(error),
);

const API_ENDPOINTS = [
  { key: 'contact', query: '/v5/contact', method: 'post' },
  { key: 'calculate', query: '/v5/calculate', method: 'post' },
];

export default API_ENDPOINTS.reduce((acc, {
  key,
  query,
  method = 'get',
  api = apiBase,
}) => {
  acc[key] = (params) => api[method](query, params);
  return acc;
}, {});
